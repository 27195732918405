import React from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
import { RiPhoneLine } from "react-icons/ri";

const Contact = () => {
  return (
    <section id="contact" className="py-5">
      <div className="container text-center d-flex flex-column justify-content-center pb-3 pt-5">
        <p className="text-muted pt-5 mb-0">let's talk to each other</p>
        <h2 className="pb-3">Contact me</h2>
      </div>

      <div className="container contact__container pb-5">
        <div className="contact__options pb-5">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h3>E-Mail</h3>
            <h4 style={{ fontWeight: "300", fontSize: "1.1rem" }}>
              kontakt@mergim-rexha.de
            </h4>
            <a
              title="Write me an E-Mail"
              className="contact_link"
              href="mailto:kontakt@mergim-rexha.de"
              rel="noreferrer"
              target="_blank"
            >
              Send me a message
            </a>
          </article>
          <article className="contact__option">
            <RiPhoneLine className="contact__option-icon" />
            <h3>Phone</h3>
            <h4 style={{ fontWeight: "300", fontSize: "1.1rem" }}>
              +49 (0) 1774 950 544
            </h4>
            <a
              href="tel:+491774950544"
              title="Call +49 1774 950 544"
              rel="noreferrer"
              target="_blank"
            >
              Call now
            </a>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Contact;
