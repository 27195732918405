import React, { useState } from "react";
import "./header.css";
import HeaderSocial from "./HeaderSocials";
import mboxSplitterLogo from "../../assets/mbox-splitter-logo-words.svg";
import * as JSZip from "jszip";

const download = async (zip) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";

  const blob = await zip.generateAsync({ type: "blob" });
  const url = window.URL.createObjectURL(blob);

  a.href = url;
  a.download = "mbox.zip";
  a.click();

  window.URL.revokeObjectURL(url);
};

const parseFile = async (file, splitSize, filePrefix, setProgress) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const zip = new JSZip();

    let restOfPreviousChunk = "";
    let index = 0;

    const STRATEGY = {
      BACKTRACK_TO_LAST_MAILSTART: "BACKTRACK_TO_LAST_MAILSTART",
      SLURP_UNTIL_NEXT_MAILSTART: "SLURP_UNTIL_NEXT_MAILSTART",
    };

    let strategy = STRATEGY.BACKTRACK_TO_LAST_MAILSTART;

    reader.onload = () => {
      const chunk = reader.result;
      const decoder = new TextDecoder("utf-8");
      const text = decoder.decode(chunk);
      if (strategy === STRATEGY.SLURP_UNTIL_NEXT_MAILSTART) {
        const indexOfNextMailStart = text.indexOf("\nFrom ");

        const textUntilNextMailStart = text.substring(0, indexOfNextMailStart);

        const mboxContent =
          restOfPreviousChunk + text.substring(0, indexOfNextMailStart);
        zip.file(`${filePrefix}${index}`, mboxContent);

        offset += new TextEncoder().encode(textUntilNextMailStart).length;
        restOfPreviousChunk = "";
        strategy = STRATEGY.BACKTRACK_TO_LAST_MAILSTART;
      } else if (strategy === STRATEGY.BACKTRACK_TO_LAST_MAILSTART) {
        const lastIndexOfMailStart = text.lastIndexOf("\nFrom ");
        if (lastIndexOfMailStart <= 0) {
          offset += chunkSize;
          restOfPreviousChunk += text;
          strategy = STRATEGY.SLURP_UNTIL_NEXT_MAILSTART;
        } else {
          const mboxContent =
            restOfPreviousChunk + text.substring(0, lastIndexOfMailStart);

          restOfPreviousChunk = text.substring(lastIndexOfMailStart);
          offset -= new TextEncoder().encode(restOfPreviousChunk).length;
          strategy = STRATEGY.BACKTRACK_TO_LAST_MAILSTART;
          zip.file(`${filePrefix}${index}`, mboxContent);
        }
      }
      index += 1;
      next();
    };

    reader.onerror = function () {
      reject(reader.error.message);
    };

    let offset = 0;
    const chunkSize = 1000 * 1000 * splitSize; // 5mb

    const next = () => {
      setProgress(Math.floor(100 * (offset / file.size)));
      if (offset >= file.size) {
        setProgress(undefined);
        resolve(zip);
        return;
      }
      const previousChunkSize = new TextEncoder().encode(
        restOfPreviousChunk
      ).length;
      const slice = file.slice(offset, offset + chunkSize - previousChunkSize);
      reader.readAsArrayBuffer(slice);
      offset += chunkSize;
    };

    next();
  });
};

const Header = () => {
  // User input
  const [selectedFile, setSelectedFile] = useState();
  const [splitSize, setSplitSize] = useState(40);
  const [filePrefix, setFilePrefix] = useState("mbox-");
  // UI states
  const [isWorking, setIsWorking] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [parsedFile, setParsedFile] = useState();

  const [progress, setProgress] = useState(undefined);

  const onFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    setIsWorking(true);
    setHasError(false);

    try {
      const zip = await parseFile(
        selectedFile,
        splitSize,
        filePrefix,
        setProgress
      );
      setParsedFile(zip);
      download(zip);
    } catch (error) {
      setHasError(true);
      throw error;
    } finally {
      setIsWorking(false);
    }
  };

  const onDownload = async () => {
    setIsWorking(true);
    try {
      await download(parsedFile);
    } catch (error) {
      setHasError(true);
      throw error;
    } finally {
      setIsWorking(false);
    }
  };

  return (
    <header id="mbox-splitter">
      {hasError && (
        <div className="alert alert-danger">
          There was an error parsing your mbox file.
        </div>
      )}
      <div className="container d-flex mt-5 justify-content-center flex-column align-items-center text-center">
        <div className="row">
          <div className="col-md-12 mb-4 mb-md-0">
            <p className="text-muted mb-0">simple & easy</p>

            <img
              title="mbox splitter logo"
              width="350px"
              src={mboxSplitterLogo}
              alt="mbox splitter logo"
            />
            <h1 className="bigger mb-md-0 mb-2" style={{ color: "#212529" }}>
              Mbox-Splitter - The best tool to divide large MBOX files online!
            </h1>
            <span className="text-muted">I'm the bigger brother of <a href="https://mbox-viewer.de" title="view your mbox files online">mbox-viewer.de</a></span>
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-12 d-flex text-center mt-md-5 mb-md-5 mb-md-0 justify-content-center position-relative upload">
              <label className="uploader-btn" htmlFor="upload-click-btn">
                {selectedFile === undefined ? (
                  "Upload your .mbox file"
                ) : (
                  <span style={{ color: "var(--color-green)" }}>
                    {selectedFile.name}
                  </span>
                )}
              </label>
              <input
                required
                onChange={onFileSelect}
                type="file"
                accept="application/mbox"
                id="upload-click-btn"
              />
            </div>
          </div>
          {/* Size */}
          <div className="row input justify-content-center input-field-size">
            <div className="col-md-7 col-8 text-start">
              <label htmlFor="mbox-file" className="fw-bold">
                Size * <br />
                <span className="description-size">
                  (set the max file size for each of the split files - in mb)
                </span>
              </label>
            </div>
            <div className="col-md-auto col-4">
              <input
                onChange={(event) => setSplitSize(event.target.value)}
                type="number"
                required
                id="mbox-file"
                defaultValue="40"
                placeholder="40"
              />
            </div>
          </div>
          {/* Size end */}
          {/* präfix */}
          <div className="mt-3 row justify-content-center input input-field-size">
            <div className="col-md-7 col-8 text-start">
              <label htmlFor="mbox-praefix" className="fw-bold">
                Prefix <br />
                <span className="description-size">
                  (set the prefix you want for splitted files naming)
                </span>
              </label>
            </div>
            <div className="col-md-auto col-4 text-end">
              <input
                default="mbox-"
                onChange={(event) => setFilePrefix(event.target.value)}
                type="text"
                required
                id="mbox-praefix"
                value={filePrefix}
                placeholder="mbox-"
              />
            </div>
          </div>
          {/* präfix end*/}
          <div className="submit-button-div mb-5">
            {parsedFile === undefined ? (
              <button
                disabled={isWorking || selectedFile === undefined}
                type="submit"
                className="submit-button"
              >
                {isWorking === true
                  ? progress !== undefined
                    ? `${progress}%`
                    : "Packing .zip..."
                  : "Split"}
              </button>
            ) : (
              <button
                disabled={isWorking}
                type="button"
                onClick={onDownload}
                className="submit-button"
              >
                {isWorking === true ? "Please wait ..." : "Download ZIP"}
              </button>
            )}
          </div>
        </form>
      </div>
      <div className="container py-md-5">
        <HeaderSocial />
        <a
          href="#data-information"
          title="Go to Data Information"
          className="scroll__down"
        >
          What happens to my E-Mails?
        </a>
      </div>
    </header>
  );
};

export default Header;
