import React from "react";
import "./dataInformation.css";

const DataInformation = () => {
  return (
    <section className="py-5" id="data-information">
      <div className="container text-md-center d-flex flex-column justify-content-center pt-sm-0 pb-3">
        <p className="text-muted pt-5 mb-0">Data information</p>
        <h2>What happens to my E-Mails?</h2>
      </div>
      <div className="container pb-5">
        <div className="row justify-content-center text-sm-left text-md-center align-items-center">
          <div className="col-md-8">
            <p>
              <span className="fw-bold">Absolut nothing!</span>
              <br />I have absolutely no interest to do anything with your
              e-mails. Neither do I store them on a server, nor do I read them
              out and look at them. The file is split via a javaScript code
              through your browser. So everything runs through your browser.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataInformation;
