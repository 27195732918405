import React from "react";
import "./howto.css";

const About = () => {
  return (
    <section className="py-5" id="howto">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-7">
            <p className="text-muted pt-5 text-left mb-0">How to</p>
            <h2>How can I export my E-Mails to a .mbox file?</h2>
          </div>
        </div>

        <div className="pb-5 d-flex flex-column align-items-center">
          <div className="row justify-content-center pt-4">
            <div className="col-md-7 text-left">
              <p>
                <span className="fw-bold">
                  There are several ways to export your E-Mails as .mbox file.
                </span>{" "}
                Depending on the email software you use, the export to .mbox
                file happens automatically. For example with the Apple Mac
                E-Mail software. Here the complete mailbox with all contained
                E-Mails will be exported automatically as an .mbox file. With
                Thunderbird or Outlook it is a little different. Here the
                default export format is not an .mbox file. In this section I
                will explain the export via the Mac E-Mail software. <br />
                <br />
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-7">
              <h2>Export your E-Mails via Mac to a mbox file</h2>
              <ul className="mt-2">
                <li>
                  <span className="fw-bold">1.</span> Open mail on your Mac and
                  select your mailbox
                </li>
                <li>
                  <span className="fw-bold">2.</span> In the menu bar at the
                  top, select Mailbox and then → Export mailbox
                </li>
                <li>
                  <span className="fw-bold">3.</span> Choose a destination
                  folder for the mailbox exports
                </li>
                <li>
                  <span className="fw-bold">4.</span> And that's it. Wait a
                  little. Depending on the mailbox size, the export may take a
                  few minutes.{" "}
                </li>
              </ul>
              <span className="fw-bold">Please note:</span>{" "}
              <i>
                {" "}
                When exporting, there is no loading indicator. The file is
                directly in the folder, but it is still being exported. You can
                see this by watching the file. The size of the file will
                increase until it stops changing. Then you know that your
                mailbox has been exported completely.
              </i>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-5">
          <div className="col-md-7">
            <h2>How can I Split my mbox file?</h2>
            <p>
              Using the mbox-splitter you can simply upload your file at the
              top, enter the size you need for splitting and enter a name for
              the respective files. After that you just have to click on "Split"
              and that's it. Your files will be split automatically.
            </p>
          </div>
        </div>

        <div className="pb-5 d-flex flex-column align-items-center">
          <div className="row justify-content-center">
            <div className="col-md-7 text-left">
              <h2>History of mbox</h2>
              <p>
                Mbox, short for mailbox, is a{" "}
                <a
                  href="https://de.wikipedia.org/wiki/Dateiformat"
                  title="read more about file format"
                >
                  file format
                </a>{" "}
                used to store multiple{" "}
                <a
                  href="https://de.wikipedia.org/wiki/E-Mail"
                  title="read more about emails"
                >
                  emails
                </a>{" "}
                in a single file. It was first introduced in the{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Research_Unix"
                  title="read more about fifth edition of unix"
                >
                  fifth edition of Unix.
                </a>{" "}
                All messages in an mbox file are concatenated and stored in a
                single file as plain text. Each message begins with the four
                characters <strong>"From"</strong> followed by a{" "}
                <strong>space</strong> (called the "From" line) and the{" "}
                <strong>sender's e-mail address.</strong> RFC 4155 defines
                another delimited space followed by a{" "}
                <a
                  href="https://en.wikipedia.org/wiki/UTC"
                  title="read more about utc"
                >
                  UTC{" "}
                </a>
                timestamp. If you are interested in the topic, you can have a
                look at this great wikipedia entry:{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Mbox"
                  title="read more about mbox"
                >
                  Wikipedia
                </a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
