import React from "react";
import "./creation.css";

// DO NOT USE THE IMAGES IN PRODUCTION

const Creation = () => {
  return (
    <section className="py-5" id="creation">
      <div className="container text-md-center d-flex flex-column justify-content-center pb-3">
        <p className="text-muted pt-5 mb-0">Story of mbox splitter</p>
        <h2>
          Why{" "}
          <a href="https://mbox-splitter.com" title="mbox-splitter">
            mbox-splitter.com
          </a>{" "}
          exists
        </h2>
      </div>
      <div className="container pb-5">
        <div className="row pt-md-5">
          <div className="col-md-4">
            <h3>How it started</h3>
            <p>
              I have many domains and have been with a reputable hosting
              provider here in Germany for years.{" "}
              <a href="https://strato.de" title="Strato website">
                Strato
              </a>{" "}
              was the standard hosting provider for me. Cheap and sufficient.
              However, I always had problems with the speed of my websites and
              the websites of my customers. I did everything to speed up the
              website{" "}
              <span className="fst-italic">
                (compress images, compress JS etc.)
              </span>{" "}
              but still this didn't change the speed of the websites too much.
              As CMS I often use{" "}
              <a href="https://wordpress.com" title="Wordpress website">
                Wordpress
              </a>
              , because you can easily and super fast create websites with it.
            </p>
          </div>
          <div className="col-md-4 pt-5 pt-md-0">
            <h3>The problem</h3>
            <p>
              At some point I came across the hosting provider{" "}
              <a href="https://1blu.de" title="1blue website">
                1blu.de
              </a>{" "}
              through a customer of mine. 1blu offers a{" "}
              <a
                href="https://www.1blu.de/webhosting/performancepakete/"
                title="1blu performance package"
              >
                performance package
              </a>
              , where a high-end NVMe-SSD hard drive ensures that you have
              ultra-fast access to databases and files. I tried it out and was
              pleasantly surprised. The website was much faster than usual. I
              started to move some domains from Strato to 1blu. Here, of course,
              I had to move the e-mail address as well. I had to create the
              e-mails of the domain I want to move at 1blu and then export the
              e-mails at Strato and import them at 1blu. I exported the e-mails
              through the Strato webmailer by selecting all of them and then
              exporting them as an .mbox file. The resulting file was 450.5 mb
              in size. I didn't think anything of it and logged into 1blu
              webmailer with my new email address to import them. It didn't work
              and I saw as error message:{" "}
              <span className="fst-italic">
                "There is only a maximum of 40mb allowed"
              </span>
              .
            </p>
          </div>
          <div className="col-md-4 pt-5 pt-md-0">
            <h3>The solution</h3>
            <p className="">
              This frustrated me enormously and I thought about what I should do
              now. I didn't want to mark a certain amount, export it and then
              import it to 1blu. I opened the .mbox file and saw a structure
              that was always the same. I wrote a script on my local machine
              that allowed me to specify a max size to split the large mbox file
              accordingly.{" "}
              <span className="fw-bold">
                And here we are. I want to share this script and this
                possibility with the world.
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Creation;
