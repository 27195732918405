import React from "react";
import "./about.css";
import mboxExplainPicture from "../../assets/mbox-splitter-picture-to-explain.svg";

const About = () => {
  return (
    <section className="py-5" id="about">
      <div className="container text-sm-left text-md-center d-flex flex-column justify-content-center">
        <p className="text-muted pt-5 mb-0">about</p>
        <h2>What is Mbox Splitter</h2>
      </div>
      <div className="container pb-5 pt-md-5">
        <div className="row mt-2 align-items-center">
          <div className="col-md-6">
            <span className="fw-bold">
              The{" "}
              <a href="https://mbox-splitter.com" title="Mbox-Splitter">
                Mbox Splitter
              </a>{" "}
              is a simple and useful tool to conveniently and quickly split
              large mbox files into multiple files.
            </span>{" "}
            <br />
            <br />
            It often happens that E-Mail providers specify a limited file size
            when importing mbox files. In my example it was 40 mb. So I thought
            about what to do. There were two options:
            <ul className="mt-2">
              <li>
                <span className="fw-bold">1.</span> I always mark a part of my
                E-Mails, for example always 200 E-Mails, instead of marking them
                all at once and export them. First I have to test if 200 E-Mails
                are ok at all and if they don't exceed the maximum file size. I
                repeat the process until I have exported all E-Mails.
              </li>
            </ul>
            If you have only a few E-Mails, you can do this, but if you have
            several E-Mails, it can be very annoying and error-prone. Or you can
            do the second way:
            <ul className="mt-3">
              <li>
                <span className="fw-bold">2.</span>The second option is to write
                a script that splits the large file the way you want it.
              </li>
            </ul>
            An exported mbox file is not magic. Anyone can open it using the
            text editor or any other similar program like notepad or Visual
            Studio. Here you can see the structure. All E-Mails are listed one
            below the other and separated with special terms. And nothing else
            is done by mbox-splitter.
          </div>
          <div className="offset-md-1 col-md-5 pb-sm-3 pb-md-5">
            <div className="border-0 text-center">
              <img
                title="Mbox Explain Picture"
                width="340px"
                src={mboxExplainPicture}
                alt="explain-mbox-splitter"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
