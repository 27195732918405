import React from "react";
import "./footer.css";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { FaXing } from "react-icons/fa";
import logo from "../../assets/mergim-rexha-logo-white.svg";
import { Link } from "react-router-dom";
import { getTitle, slugs } from "../blog/blog-items";

const Footer = () => {
  return (
    <footer>
      <div className="container d-flex flex-column ">
        <div className="row">
          <div className="col-md-12 justify-content-center">
            <a
              href="https://mergim-rexha.de"
              title="Visit my main website"
            >
              <img
                title="Logo of mergim-rexha.de"
                href="https://mergim-rexha.de"
                src={logo}
                className="logo"
                alt="Logo Mergim Rexha"
              />
            </a>
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className="col-md-4">
            <p className="color-white bigger footer-headline">
              <strong>All about Mbox-Splitter & E-Mails </strong>
            </p>
            <ul className="d-flex flex-column justify-content-center ps-0 gap-3 pb-4 align-items-center">
              {slugs.map((slug) => (
                <li key={slug}>
                  <Link
                    to={`/blog/${slug}`}
                    title={`Blog: Read more about ${getTitle(slug)}`}
                  >
                    {getTitle(slug)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className="col-md-4">
            <p className="color-white bigger footer-headline">
              <strong>General information </strong>
            </p>
            <ul className="d-flex flex-column justify-content-center ps-0 gap-3 pb-4 align-items-center">
              <li>
                <a href="/#mbox-splitter" title="Go to home">
                  Home
                </a>
              </li>
              <li>
                <a href="/blog" title="Go to my Blog">
                  Blog
                </a>
              </li>
              <li>
                <a href="/#about" title="Go to About">
                  About
                </a>
              </li>
              <li>
                <a href="/#data-information" title="Go to Data information">
                  Data information
                </a>
              </li>
              <li>
                <a href="/#creation" title="Go to Story">
                  Story
                </a>
              </li>
              <li>
                <a href="/#howto" title="Go to How to">
                  How to
                </a>
              </li>
              <li>
                <a href="/#me" title="Go to About me">
                  About me
                </a>
              </li>
              <li>
                <a href="/#contact" title="Go to Contact">
                  Contact
                </a>
              </li>
              <li>
                <Link to="/Imprint" title="Go to Imprint">
                  Imprint
                </Link>
              </li>
              <li>
                <Link to="/Privacy" title="Go to Privacy">
                  Privacy
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="row justify-content-center ">
          <div className="col-md-4">
            <p className="color-white bigger footer-headline">
              <strong>Other Websites </strong>
            </p>
            <ul className="d-flex flex-column justify-content-center ps-0 gap-3 pb-4 align-items-center">
            <li>
                <a href="https://mbox-viewer.de" title="view your mbox files online">
                  mbox-viewer.de
                </a>
              </li>
              <li>
                <a href="https://text-counter.com" title="Count your Text">
                  text-counter.com
                </a>
              </li>
              <li>
              <a href="https://cleanita.de" title="mobil bidet">
                  cleanita.de
                  </a>
              </li>
              <li>
                <a href="https://apply-high.de" title="Generate your application">
                  apply-high.de
                </a>
              </li>
              <li>
                <a href="https://rexhawood.de" title="Our OnlineShop">
                  rexhawood.de
                </a>
              </li>
              
            </ul>
          </div>
        </div>

        <div className="footer__socials">
          <a
            title="Visit my Linkedin profile"
            href="https://www.linkedin.com/in/mergim-rexha/"
          >
            <BsLinkedin />
          </a>
          <a
            title="Visit my Xing profile"
            href="https://www.xing.com/profile/Mergim_Rexha3/cv"
          >
            <FaXing />
          </a>
          <a
            title="Visit my GitHub profile"
            href="https://github.com/mergimrexha"
          >
            <FaGithub />
          </a>
        </div>

        <div className="footer__copyright">
          <small>
            &copy;{" "}
            <a
              title="Visit my main website"
              className="text-decoration-underline"
              href="https://mergim-rexha.de"
            >
              Mergim Rexha
            </a>
            . All rights reserved.
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
